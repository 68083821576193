import React from 'react';
import { getFormatedForm } from '../../../Models';
import step2Model from '../../../Models/createUserRequestModels/step2Model';
import { Formik, Form } from 'formik';
import {
  SzInput,
  SzButton,
  SzAlert,
  SzCheckbox,
} from '@suezenv/react-theme-components';
import './createForm.scss';
import { withTranslation } from 'react-i18next';
import { Select } from '../../elements/select';
import {
  SUBSCRIBER_CONST,
  OFFICE_CONST,
  ORGANISATION_CONST,
} from '../../../services/RequesterService';
import { NOT_SET_VALUE, Status, RequestAttributes } from '../../../constants';

class Step2Form extends React.Component<any> {
  state = {
    isValid: false,
    isNextStepDisabled: false,
    contactName: '',
    contactType: SUBSCRIBER_CONST,
    contactTypeSubscriber: '',
    contactTypeOffice: '',
    contactTypeOrganisation: '',
    contactEmail: '',
    contactPhone: '',
    contactThirdPartyNotify: '',
    contactShareContactData: false,
    showForm: null,
  };

  componentDidMount() {
    const { values } = this.props;
    const { data, id } = values;
    const { contactName } = data;

    if (id) {
      if (!contactName || contactName === NOT_SET_VALUE) {
        this.setState({ showForm: false });
        return;
      }
      const contactType = data.contactType ? data.contactType : '';
      const contactTypeSubscriber = data.contactTypeSubscriber
        ? data.contactTypeSubscriber
        : '';
      const contactTypeOffice = data.contactTypeOffice
        ? data.contactTypeOffice
        : '';
      const contactTypeOrganisation = data.contactTypeOrganisation
        ? data.contactTypeOrganisation
        : '';
      const contactShareContactData = data.contactShareContactData === '1';
      const contactEmail = data.contactEmail ? data.contactEmail : '';
      const contactPhone = data.contactPhone ? data.contactPhone : '';
      const contactThirdPartyNotify = data.contactThirdPartyNotify ? data.contactThirdPartyNotify : '';
      this.setState({
        showForm: true,
        contactShareContactData,
        contactName,
        contactEmail,
        contactPhone,
        contactType,
        contactTypeSubscriber,
        contactThirdPartyNotify,
        contactTypeOffice,
        contactTypeOrganisation,
      });
    }
  }

  changeHandle(e: any) {
    this.setState({ [e.target.name]: e.target.value });
  }

  getSubscriberOptions() {
    let options: any = [];
    if (this.props.dataForm) {
      Object.entries(this.props.dataForm).map((value: any) => {
        const option = {
          text: value[1].label,
          value: value[0],
        };
        options.push(option);

        return option;
      });
    }

    return options;
  }

  getFieldName(fieldName: any) {
    //fixMe : trouve une solution plus dynamique
    switch (fieldName) {
      case RequestAttributes.CONTACT_TYPE_SUBSCRIBER:
        return this.state.contactTypeSubscriber;
      case RequestAttributes.CONTACT_TYPE_OFFICE:
        return this.state.contactTypeOffice;
      case RequestAttributes.CONTACT_TYPE_ORGANISATION:
        return this.state.contactTypeOrganisation;
      default:
        return '';
    }
  }

  getCleanValues() {
    const {
      contactName,
      contactType,
      contactEmail,
      contactPhone,
      contactTypeSubscriber,
      contactTypeOffice,
      contactTypeOrganisation,
      contactShareContactData,
      contactThirdPartyNotify,
      showForm,
    } = this.state;

    if (!showForm) {
      return {};
    }
    const formValue = {
      contactName,
      contactType,
      contactEmail,
      contactPhone,
      contactThirdPartyNotify,
      contactShareContactData,
    };
    if (contactType === SUBSCRIBER_CONST) {
      return { ...formValue, contactTypeSubscriber };
    }
    if (contactType === OFFICE_CONST) {
      return { ...formValue, contactTypeOffice };
    }
    if (contactType === ORGANISATION_CONST) {
      return { ...formValue, contactTypeOrganisation };
    }

    return formValue;
  }

  toggleFormHandle(showForm: boolean) {
    this.setState({ showForm: showForm });
  }

  tempNextStepDisabled(isNextStepDisabled: boolean) {
    this.setState({ isNextStepDisabled: isNextStepDisabled });
    setTimeout(() => {
      this.setState({ isNextStepDisabled: !isNextStepDisabled });
    }, 2000);
  }

  renderToggleForm() {
    const { showForm } = this.state;
    const showButton = showForm !== true ? 'secondary' : 'primary';
    const hideButton = showForm !== false ? 'secondary' : 'primary';
    const { t } = this.props;
    return (
      <div className="row box">
        <div className="col">
          <div className="row justify-content-around m-4">
            <div className="col-auto">
              <p>{t('requesting_for_third_party')}</p>
            </div>
          </div>
          <div className="row toggle-form-actions">
            <div className="col-5 offset-1 col-md-2 offset-md-4">
              <SzButton
                onClick={() => this.toggleFormHandle(true)}
                variant={showButton}
                className="show-form-btn w-100"
              >
                {t('yes_btn')}
              </SzButton>
            </div>
            <div className="col-5 col-md-2">
              <SzButton
                onClick={() => this.toggleFormHandle(false)}
                variant={hideButton}
                className="hide-form-btn w-100"
              >
                {t('no_btn')}
              </SzButton>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidUpdate(props: any) {
    if (!this.state.contactTypeOrganisation && props.dataForm) {
      const organisationData = props.dataForm[ORGANISATION_CONST];
      const { field } = organisationData;
      const contactTypeOrganisation =
        organisationData && field.options[0] ? field.options[0].value : '';
      this.setState({ contactTypeOrganisation });
    }
  }

  public render() {
    const [schema] = getFormatedForm(step2Model);
    const {
      t,
      nextStepHandle,
      saveDraft,
      dataForm,
      previousStepHandle,
      values,
    } = this.props;
    const selectedType = dataForm ? dataForm[this.state.contactType] : null;
    const { showForm, isNextStepDisabled, isValid } = this.state;
    const isDraft =
      values.id && values.currentStatus.status.id === Status.STATUS_DRAFT;
    const isAutoSavedDraft = values.id && !values.currentStatus.status.id;
    const showDraft = isDraft || !values.id || isAutoSavedDraft;

    if (
      selectedType &&
      selectedType.field &&
      selectedType.field.options &&
      selectedType.field.options[0].text.label && // prevents reparsing
      RequestAttributes.CONTACT_TYPE_ORGANISATION === selectedType.field.name
    ) {
      selectedType.field.options = selectedType.field.options.map(
        (option: any) => {
          return {
            text: option.text.label,
            value: option.value,
          };
        }
      );
    }

    return (
      <>
        {this.renderToggleForm()}
        <Formik
          validationSchema={schema}
          onSubmit={() => {}}
          initialValues={this.state}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({ submitForm, errors, setFieldError, setErrors }) => {
            const {
              contactName,
              contactType,
              contactEmail,
              contactPhone,
              contactThirdPartyNotify,
              contactShareContactData,
            } = step2Model;
            schema.isValid(this.state).then((isValidForm: any) => {
              if (isValidForm !== isValid) {
                setErrors({});
                this.setState({ isValid: isValidForm });
              }
            });
            const customHandleBlur = (e: any) => {
              let targetName: string = e.target.name;
              this.setState({ changing: false });
              schema
                .validateAt(targetName, {
                  ...this.state,
                  [targetName]: e.target.value,
                })
                .then(() => {
                  const newErrors = { ...errors };
                  deleteFromObject(targetName, newErrors);
                  setErrors(newErrors);
                })
                .catch((error: any) => {
                  const { path, message } = error;
                  setFieldError(path, message);
                });
            };

            const deleteFromObject = (toDeleteKey: string, object: any) => {
              for (let index in object) {
                if (index === toDeleteKey) {
                  delete object[index];
                }
              }
            };
            const nextStep = () => {
              if (!isValid && showForm) {
                submitForm();
              } else {
                this.tempNextStepDisabled(true);
                nextStepHandle(this.getCleanValues());
              }
            };
            const changeCheckboxHandle = () => {
              const contactShareContactData = !this.state
                .contactShareContactData;
              this.setState({
                contactShareContactData,
                contactEmail: '',
                contactPhone: '',
                contactThirdPartyNotify: '',
              });
              errors['contactPhone'] = errors['contactEmail'] = '';
            };
            return (
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (!isValid && showForm) {
                    submitForm();
                  } else {
                    saveDraft(this.getCleanValues());
                  }
                }}
              >
                {showForm && (
                  <div className="row box">
                    <div className="col-12 col-md-6">
                      <div>
                        <SzInput
                          onChange={this.changeHandle.bind(this)}
                          label={t(contactName.label)}
                          name={contactName.name}
                          onBlur={customHandleBlur}
                          icon={contactName.icon}
                          required={contactName.required}
                          placeholder={contactName.placeholder}
                          value={this.state.contactName}
                        />
                        {errors['contactName'] && (
                          <SzAlert variant="danger">
                            <>{t(errors['contactName'])}</>
                          </SzAlert>
                        )}
                      </div>
                      <div>
                        <Select
                          onChange={this.changeHandle.bind(this)}
                          onBlur={customHandleBlur}
                          label={t(contactType.label)}
                          options={this.getSubscriberOptions()}
                          value={this.state.contactType}
                          name={contactType.name}
                        />

                        {errors['contactType'] && (
                          <SzAlert variant="danger">
                            <>{t(errors['contactType'])}</>
                          </SzAlert>
                        )}
                      </div>
                      <div>
                        {selectedType &&
                          selectedType.field &&
                          selectedType.field.type === 'text' && (
                            <SzInput
                              onChange={this.changeHandle.bind(this)}
                              label={t(selectedType.field.label)}
                              name={selectedType.field.name}
                              onBlur={() => {}}
                              icon=""
                              required={selectedType.field.required}
                              placeholder=""
                              type={selectedType.field.type}
                              value={this.getFieldName(selectedType.field.name)}
                            />
                          )}
                        {selectedType &&
                          selectedType.field &&
                          selectedType.field.type === 'select' && (
                            <Select
                              onChange={this.changeHandle.bind(this)}
                              onBlur={() => {}}
                              label={t(selectedType.field.label)}
                              options={selectedType.field.options}
                              name={selectedType.field.name}
                              required={selectedType.field.required}
                              value={this.getFieldName(selectedType.field.name)}
                            />
                          )}
                      </div>
                      <div className="mb-3 mt-2 ml-3">
                        <SzCheckbox
                          label={t(contactShareContactData.label)}
                          checked={this.state.contactShareContactData}
                          type={'checkbox'}
                          onChange={changeCheckboxHandle.bind(this)}
                          name={contactShareContactData.name}
                          id={contactShareContactData.name}
                        />
                      </div>
                      {this.state.contactShareContactData && (
                        <>
                          <div>
                            <SzInput
                              onChange={this.changeHandle.bind(this)}
                              label={t(contactEmail.label)}
                              name={contactEmail.name}
                              onBlur={customHandleBlur}
                              icon={contactEmail.icon}
                              required={contactEmail.required}
                              placeholder={contactEmail.placeholder}
                              value={this.state.contactEmail}
                            />
                            {errors['contactEmail'] && (
                              <SzAlert variant="danger">
                                <>{t(errors['contactEmail'])}</>
                              </SzAlert>
                            )}
                          </div>
                          <div>
                            <SzInput
                              onChange={this.changeHandle.bind(this)}
                              label={t(contactPhone.label)}
                              name={contactPhone.name}
                              onBlur={customHandleBlur}
                              icon={contactPhone.icon}
                              required={contactPhone.required}
                              placeholder={contactPhone.placeholder}
                              value={this.state.contactPhone}
                            />
                            {errors['contactPhone'] && (
                              <SzAlert variant="danger">
                                <>{t(errors['contactPhone'])}</>
                              </SzAlert>
                            )}
                          </div>
                          <div>
                            <SzInput
                              onChange={this.changeHandle.bind(this)}
                              label={t(contactThirdPartyNotify.label)}
                              name={contactThirdPartyNotify.name}
                              onBlur={customHandleBlur}
                              placeholder={contactThirdPartyNotify.placeholder}
                              value={this.state.contactThirdPartyNotify}
                              type="email"
                              required={contactThirdPartyNotify.required}
                            />
                            {errors['contactThirdPartyNotify'] && (
                              <SzAlert variant="danger">
                                <>{t(errors['contactThirdPartyNotify'])}</>
                              </SzAlert>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}
                <div className="row step-actions">
                  <div className="col-7 col-sm-5 col-md-3">
                    <SzButton
                      icon="arrow-left"
                      onClick={previousStepHandle}
                      className="previous-btn w-100"
                      variant="secondary"
                    >
                      {t('step_previous_btn', { number: 1 })}
                    </SzButton>
                  </div>
                  {showForm !== null && (
                    <>
                      <div
                        className={
                          showDraft ? 'col-5 col-sm-5 offset-sm-2 col-md-2 offset-md-4' : 'col-5 col-sm-5 offset-sm-2 col-md-2 offset-md-7'
                        }
                      >
                        <SzButton
                          className="next-btn w-100"
                          isDisabled={
                            isNextStepDisabled ||
                            (!isValid && showForm === true)
                          }
                          onClick={nextStep}
                        >
                          {t('next_btn')}
                        </SzButton>
                      </div>
                      {showDraft && (
                        <>
                          <div className="col-sm-5 col-md-3 d-none d-md-block">
                            <SzButton
                              className="draft-btn w-100"
                              isDisabled={!isValid && showForm === true}
                              type="submit"
                              variant="secondary"
                            >
                              {t('draft_btn')}
                            </SzButton>
                          </div>
                          <div className="col-12 pt-3 d-block d-md-none">
                            <SzButton
                              className="draft-btn w-100"
                              isDisabled={!isValid && showForm === true}
                              type="submit"
                              variant="secondary"
                            >
                              {t('draft_btn_full')}
                            </SzButton>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </>
    );
  }
}

export default withTranslation()(Step2Form);

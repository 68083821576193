import React from "react";
import { connect } from "react-redux";
import './createUserRequest.scss';
import { updateCurrentUserRequest } from "../../actions";
import { withRouter } from "react-router";
import Step2Form from "../../components/forms/createUserRequest/Step2Form";
import { StepManager } from "./StepManager";
import UserRequestsService from "../../services/UserRequestsService";

class Step2Manager extends StepManager {
    state = { isDisabledBtn: true };

    constructor(props: any) {
        super(props);
        if (!props.dataForm) {
            UserRequestsService.getMdmData();
        }
    }

    formValuesToUserRequest(formValues: any): any {
        let { userRequest } = this.props;
        const userRequestData = userRequest.data;

        // We have to delete all step2 props
        delete userRequestData['contactTypeSubscriber'];
        delete userRequestData['contactTypeOffice'];
        delete userRequestData['contactTypeOrganisation'];
        delete userRequestData['contactType'];
        delete userRequestData['contactName'];
        delete userRequestData['contactPhone'];
        delete userRequestData['contactEmail'];
        delete userRequestData['contactShareContactData'];
        delete userRequestData['contactThirdPartyNotify'];
        
        return {
            ...userRequest, data: { ...userRequestData, ...formValues }
        }
    }

    public render() {
        const { userRequest, dataForm, previousStepHandle } = this.props;
        return (
            <Step2Form
                values={userRequest}
                saveDraft={this.saveDraft.bind(this)}
                nextStepHandle={this.nextStepHandle.bind(this)}
                previousStepHandle={previousStepHandle.bind(this)}
                dataForm={dataForm}
            />
        );
    }
}

const mapStateToProps = (state: any) => ({
    userRequest: state.userRequests.currentRequest,
    requestFiles: state.userRequests.currentRequestFiles,
    dataForm: state.requester.dataForm,
    mdmData: state.userRequests.mdmData

});

const mapDispatchToProps = {
    updateCurrentUserRequest: updateCurrentUserRequest
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Step2Manager));
